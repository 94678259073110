export const translations = {
    english: {
        Login: {
            email: 'Email',
            password: 'Password',
            rememberMe: 'Remember me',
            login: 'Login',
            forgotPassword: 'Forgot Password?',
            logout: 'Logout',
            noAccount: 'No account yet?',
            signupForFree: 'Sign up for free',
            signup: 'Sign up',
            displayName: 'Display Name',
            phoneNumber: 'Phone Number',
            passwordCheck: 'Password must be at least 8 characters and contain at least 1 letter and 1 number',
            agree: 'I agree to the ',
            and: 'and',
            termOfUse: 'Term of Use',
            privacyPolicy: 'Privacy Policy',
            createAccount: 'Create Account',
            alreadyHave: 'Already have an account?',
            checkInbox: 'Please check your inbox',
            checkNote: 'A verification email has been sent to david@gocochain.com. Please check and verify your account before logging in.',
            confirmAccount: 'Confirm your Gocochain account',
            confirmNote: "Welcome to Gocochain! You're receiving this message because you recently signed up for a Gocochain account. Confirm your email address by clicking the button below and start your monitoring now!",
            confirm: 'Confirm Account',
            successAccount: 'Success!',
            successNote: 'Your account has been activated.',
            return: 'Return to login',
            reset: 'Reset Password',
            confirmPassword: 'Confirm Password',
            mailSuccess: 'Success!',
            resetNote1: 'Enter the email address that you used to register.',
            resetNote2: "We'll email you instructions on how to reset your password.",
            send: 'Send',
            success: 'Success!',
            resetSuccessNote: "We've sent an email with instructions to your email address. Please check your inbox.",
            resetMail: 'Reset your password',
            resetMailNote: 'You are receiving this email because someone requested a password reset. Please click the button below to reset your password.',
            resetMailNotice: 'If you did not request a password reset, please ignore this email.',
            newPassword: 'New password',
            reenterPassword: 'Re-enter password',
            resetSuccess: 'Your password has been reset.',
            question: 'Questions? contact',
        },
        Overview: {
            title: 'Overview',
            logout: 'Logout',
            dayUpload: 'Uploads Today',
            dayError: 'Fails Today',
            weekUpload: 'Uploads This Week',
            weekError: 'Fails This Week',
            dayErrorRate: 'Fail Rate Today',
            perDayUpload: 'Daily Uploads',
            averageTemp: 'Average Temperature',
            maxTemp: 'Max Temp',
            minTemp: 'Min Temp',
            recentUpload: 'Recent Uploads',
            uploadTime: 'Upload Time',
            district: 'District',
            deliveryCompany: 'Shipper',
            pass: 'Pass',
            fail: 'Fail',
            range: 'Data Range',
            currentWeek: 'Current Week',
            lastWeek: 'Last Week',
            currentMonth: 'Current Month',
            lastMonth: 'Last Month',
            errorRate: 'Fail Rate',
            failLastedTime: 'Lasted Fail Time',
            passAverageTemp: 'Pass Average Temperature',
            failAverageTemp: 'Fail Average Temperature',
            pass: 'Pass',
            fail: 'Fail',
            day: 'd',
            hour: 'h',
            minute: 'm',
            uploadCount: 'Uploads',
            errorCount: 'Fails',
            time: 'Time',
            region: 'Region',
            company: 'Company',
            status: 'Status',
        },
        Pending: {
            title: 'Not Uploaded',
            add: 'Add',
            startTime: 'Start Time',
            endTime: 'End Time',
            search: 'Search',
            config: 'Configure',
            edit: 'Edit',
            export: 'Export',
            confirm: 'Confirm',
            Id: 'No.',
            deviceId: 'Device ID',
            recordTime: 'Record Time',
            count: 'Number of Records',
            timeZone: 'Time Zone',
            district: 'District',
            deliveryCompany: 'Shipper',
            trackNumber: 'Tracking Number',
            status: 'Status',
            pass: 'Pass',
            fail: 'Fail',
            tempRecordList: 'Temperature Records',
            time: 'Time',
            temp: 'Temperature',
            trendGraph: 'Trend Graph',
            duration: 'Duration',
            to: 'to',
            outof: 'Out of',
            for: 'for',
            range: '',
            minutes: 'minutes',
            continuous: 'Single Event',
            cumulative: 'Cumulative',
            failAlarm: 'Fail Alarm',
            D: 'D',
            H: 'H',
            M: 'M',
            note: 'Note',
            Add: {
                addCotag: 'Add a CoTag',
                deviceId: 'Device ID',
                add: 'Add',
                confirmText: 'Confirm adding this Device ID?',
                confirm: 'Confirm',
                cancel: 'Cancel',
                addSuccess: 'Add successfully',
                addFail: 'Add failed',
            },
            Edit: {
                edit: 'Edit',
                deviceId: 'Device ID',
                information: 'Information',
                configure: 'Configure',
                shipmentNumber: 'Shipment Number',
                shipper: 'Shipper',
                carrier: 'Carrier',
                consignee: 'Consignee',
                startLocation: 'Start Location',
                endLocation: 'End Location',
                note: 'Note',
                failAlarm: 'Fail Alarm',
                excursionType: 'Excursion Type',
                singleEvent: 'Single Event',
                cumulative: 'Cumulative',
                S: 'S',
                M: 'M',
                H: 'H',
                D: 'D',
                upperLimit: 'Upper Limit',
                lowerLimit: 'Lower Limit',
                duration: 'Duration',
                dataLogging: 'Data Logging',
                loggingInterval: 'Logging Interval',
                alarmNotification: 'Alarm Notification',
                email: 'Email',
                saveAll: 'Save All',
                saveSuccess: 'Save successfully',
            },
        },
        Records: {
            title: 'Upload Successful',
            startTime: 'Start Time',
            endTime: 'End Time',
            search: 'Search',
            config: 'Configure',
            edit: 'Edit',
            export: 'Export',
            confirm: 'Confirm',
            Id: 'No.',
            deviceId: 'Device ID',
            recordTime: 'Record Time',
            count: 'Number of Records',
            timeZone: 'Time Zone',
            district: 'District',
            deliveryCompany: 'Shipper',
            trackNumber: 'Tracking Number',
            status: 'Status',
            pass: 'Pass',
            fail: 'Fail',
            normal: 'normal',
            abnormal: 'abnormal',
            tempRecordList: 'Temperature Records',
            time: 'Time',
            temp: 'Temperature',
            trendGraph: 'Trend Graph',
            duration: 'Duration',
            to: 'to',
            outof: 'Out of',
            for: 'for',
            range: '',
            minutes: 'minutes',
            continuous: 'Single Event',
            cumulative: 'Cumulative',
            failAlarm: 'Fail Alarm',
            D: 'D',
            H: 'H',
            M: 'M',
            note: 'Note',
            Edit: {
                edit: 'Edit',
                deviceId: 'Device ID',
                information: 'Information',
                configure: 'Configure',
                shipmentNumber: 'Shipment Number',
                shipper: 'Shipper',
                carrier: 'Carrier',
                consignee: 'Consignee',
                startLocation: 'Start Location',
                endLocation: 'End Location',
                note: 'Note',
                failAlarm: 'Fail Alarm',
                excursionType: 'Excursion Type',
                singleEvent: 'Single Event',
                cumulative: 'Cumulative',
                S: 'S',
                M: 'M',
                H: 'H',
                D: 'D',
                upperLimit: 'Upper Limit',
                lowerLimit: 'Lower Limit',
                duration: 'Duration',
                dataLogging: 'Data Logging',
                loggingInterval: 'Logging Interval',
                alarmNotification: 'Alarm Notification',
                email: 'Email',
                saveAll: 'Save All',
            },
        },
        RealTime: {
            title: 'Real Time',
            add: 'Add',
            edit: 'Edit',
            normal: 'normal',
            abnormal: 'abnormal',
            search: 'Search',
            confirm: 'Confirm',
            Edit: {
                edit: 'Edit',
                deviceId: 'Device ID',
                information: 'Information',
                configure: 'Configure',
                shipmentNumber: 'Shipment Number',
                shipper: 'Shipper',
                carrier: 'Carrier',
                consignee: 'Consignee',
                startLocation: 'Start Location',
                endLocation: 'End Location',
                note: 'Note',
                failAlarm: 'Fail Alarm',
                excursionType: 'Excursion Type',
                singleEvent: 'Single Event',
                cumulative: 'Cumulative',
                S: 'S',
                M: 'M',
                H: 'H',
                D: 'D',
                upperLimit: 'Upper Limit',
                lowerLimit: 'Lower Limit',
                duration: 'Duration',
                dataLogging: 'Data Logging',
                loggingInterval: 'Logging Interval',
                alarmNotification: 'Alarm Notification',
                email: 'Email',
                saveAll: 'Save All',
                saveSuccess: 'Save successfully',
            },
        },
        Information: {
            title: 'Settings',
            account: 'Account',
            displayName: 'Display Name',
            email: 'Email Address',
            phone: 'Phone Number',
            preference: 'Preference',
            language: 'Language',
            tempUnit: 'Temperature Unit',
            celsius: 'Celsius (°C)',
            fahrenheit: 'Fahrenheit (°F)',
            timeZone: 'Time Zone',
            save: 'Save',
        },
        Download: {
            title: 'Download Center',
            subtitle: 'Get your data reports or calibration certificates of Gocochain devices.',
            deviceId: 'Device ID',
            hint: 'Enter Device ID on the back of your CoTag',
            confirm: 'Confirm',
            footer: 'Questions? contact support@gocochain.com',
            report: 'Download Data Report',
            certificate: 'Download Calibration Certificate',
            prepare: 'Preparing to download',
        },
    },
    chinese: {
        Login: {
            email: '電子信箱',
            password: '密碼',
            rememberMe: '記住密碼',
            login: '登入',
            forgotPassword: '忘記密碼?',
            logout: '登出',
            noAccount: '還未擁有帳號？',
            signupForFree: '免費註冊',
            signup: '註冊帳號',
            displayName: '帳號名稱',
            phoneNumber: '電話號碼',
            passwordCheck: '密碼必須至少包含8個字符，並且至少包含1個字母和1個數字。',
            agree: '我已詳細閱讀並同意',
            and: '與',
            termOfUse: '使用條款',
            privacyPolicy: '隱私權政策',
            createAccount: '建立帳號',
            alreadyHave: '已經擁有帳號？',
            checkInbox: '請查看信箱',
            checkNote: '我們已寄出一封驗證信至 david@gocochain.com，請先至信箱確認後再進行登入。',
            confirmAccount: '確認您的 Gocochain 帳號',
            confirmNote: '歡迎來到 Gocochain! 您近期註冊建立了 Gocochain 帳號，點擊下方按鈕開始啟用您的帳號。',
            confirm: '啟用',
            successAccount: '啟用成功！',
            successNote: '您的帳號已經完成驗證，可以登入使用。',
            return: '返回登入',
            reset: '重設密碼',
            confirmPassword: '確認密碼',
            mailSuccess: '信件成功寄出',
            resetNote1: '請輸入您註冊時使用的電子信箱，',
            resetNote2: '我們會將重設密碼的方式寄到該信箱。',
            send: '送出',
            success: '成功！',
            resetSuccessNote: '我們已寄出重設密碼的指引到您的電子信箱，請前往查收。',
            resetMail: '重設密碼',
            resetMailNote: '您近期要求重設 Gocochain 密碼，點擊下方按鈕以重新設定。',
            resetMailNotice: '若您並未要求重設密碼，請忽略此封信。',
            newPassword: '新密碼',
            reenterPassword: '確認密碼',
            resetSuccess: '重設密碼成功',
            question: '有其他問題？歡迎聯繫',
        },
        Overview: {
            title: '總覽',
            logout: '登出',
            dayUpload: '本日上傳筆數',
            dayError: '本日未通過筆數',
            weekUpload: '本週上傳筆數',
            weekError: '本週未通過筆數',
            dayErrorRate: '本日未通過比例',
            perDayUpload: '每日上傳筆數',
            averageTemp: '歷程平均溫度',
            maxTemp: '最高溫',
            minTemp: '最低溫',
            recentUpload: '近期上傳資訊',
            uploadTime: '上傳時間',
            district: '地區',
            deliveryCompany: '貨運業者',
            pass: '通過',
            fail: '未通過',
            range: '數據時間',
            currentWeek: '本週',
            lastWeek: '上週',
            currentMonth: '本月',
            lastMonth: '上月',
            errorRate: '未通過比例',
            failLastedTime: '未通過持續時長',
            passAverageTemp: '通過平均溫度',
            failAverageTemp: '未通過平均溫度',
            pass: '通過',
            fail: '未過',
            day: '天',
            hour: '時',
            minute: '分',
            uploadCount: '上傳筆數',
            errorCount: '未通過筆數',
            time: '時間',
            region: '地區',
            company: '公司',
            status: '狀態',
        },
        Pending: {
            title: '尚未上傳',
            startTime: '開始時間',
            endTime: '結束時間',
            search: '查詢',
            config: '設定',
            add: '新增',
            edit: '編輯',
            export: '匯出',
            confirm: '確認',
            Id: 'No.',
            deviceId: '裝置ID',
            recordTime: '記錄時間',
            count: '資料筆數',
            timeZone: '時區',
            district: '地區',
            deliveryCompany: '貨運業者',
            trackNumber: '貨運單號',
            status: '狀態',
            pass: '通過',
            fail: '未通過',
            tempRecordList: '溫度記錄列表',
            time: '時間',
            temp: '溫度',
            trendGraph: '趨勢變化圖',
            duration: '持續時間',
            to: '至',
            outof: '溫度超出',
            for: '',
            range: '範圍',
            minutes: '分鐘',
            continuous: '單一事件',
            cumulative: '累計情況',
            failAlarm: '未通過條件',
            D: '天',
            H: '時',
            M: '分',
            note: '備註',
            Add: {
                addCotag: '新增 CoTag',
                deviceId: '裝置ID',
                add: '新增',
                confirmText: '確認新增此裝置ID?',
                confirm: '確認',
                cancel: '取消',
                addSuccess: '新增成功',
                addFail: '新增失敗',
            },
            Edit: {
                edit: '編輯',
                deviceId: '裝置ID',
                information: '運輸資訊',
                configure: '裝置設定',
                shipmentNumber: '運輸單號',
                shipper: '寄件者',
                carrier: '承運商',
                consignee: '收件者',
                startLocation: '起始地',
                endLocation: '目的地',
                note: '備註',
                failAlarm: '未通過警示',
                excursionType: '警示類別',
                singleEvent: '單一事件',
                cumulative: '累計情況',
                S: '秒',
                M: '分',
                H: '時',
                D: '天',
                upperLimit: '溫度上限',
                lowerLimit: '溫度下限',
                duration: '持續時間',
                dataLogging: '數據記錄',
                loggingInterval: '記錄間隔',
                alarmNotification: '警示通知',
                email: '電子信箱',
                saveAll: '儲存',
                saveSuccess: '儲存成功',
            },
        },
        Records: {
            title: '上傳成功',
            startTime: '開始時間',
            endTime: '結束時間',
            search: '查詢',
            config: '設定',
            edit: '編輯',
            export: '匯出',
            confirm: '確認',
            Id: 'No.',
            deviceId: '裝置ID',
            recordTime: '記錄時間',
            count: '資料筆數',
            timeZone: '時區',
            district: '地區',
            deliveryCompany: '貨運業者',
            trackNumber: '貨運單號',
            status: '狀態',
            pass: '通過',
            fail: '未通過',
            normal: '正常',
            abnormal: '異常',
            tempRecordList: '溫度記錄列表',
            time: '時間',
            temp: '溫度',
            trendGraph: '趨勢變化圖',
            duration: '持續時間',
            to: '至',
            outof: '溫度超出',
            for: '',
            range: '範圍',
            minutes: '分鐘',
            continuous: '單一事件',
            cumulative: '累計情況',
            failAlarm: '未通過條件',
            D: '天',
            H: '時',
            M: '分',
            note: '備註',
            Edit: {
                edit: '編輯',
                deviceId: '裝置ID',
                information: '運輸資訊',
                configure: '裝置設定',
                shipmentNumber: '運輸單號',
                shipper: '寄件者',
                carrier: '承運商',
                consignee: '收件者',
                startLocation: '起始地',
                endLocation: '目的地',
                note: '備註',
                failAlarm: '未通過警示',
                excursionType: '警示類別',
                singleEvent: '單一事件',
                cumulative: '累計情況',
                S: '秒',
                M: '分',
                H: '時',
                D: '天',
                upperLimit: '溫度上限',
                lowerLimit: '溫度下限',
                duration: '持續時間',
                dataLogging: '數據記錄',
                loggingInterval: '記錄間隔',
                alarmNotification: '警示通知',
                email: '電子信箱',
                saveAll: '儲存',
            },
        },
        RealTime: {
            title: '即時監控',
            add: '新增',
            edit: '編輯',
            normal: '正常',
            abnormal: '異常',
            search: '查詢',
            confirm: '確認',
            Edit: {
                edit: '編輯',
                deviceId: '裝置ID',
                information: '運輸資訊',
                configure: '裝置設定',
                shipmentNumber: '運輸單號',
                shipper: '寄件者',
                carrier: '承運商',
                consignee: '收件者',
                startLocation: '起始地',
                endLocation: '目的地',
                note: '備註',
                failAlarm: '未通過警示',
                excursionType: '警示類別',
                singleEvent: '單一事件',
                cumulative: '累計情況',
                S: '秒',
                M: '分',
                H: '時',
                D: '天',
                upperLimit: '溫度上限',
                lowerLimit: '溫度下限',
                duration: '持續時間',
                dataLogging: '數據記錄',
                loggingInterval: '記錄間隔',
                alarmNotification: '警示通知',
                email: '電子信箱',
                saveAll: '儲存',
                saveSuccess: '儲存成功',
            },
        },
        Information: {
            title: '設定',
            account: '帳號設定',
            displayName: '顯示名稱',
            email: '電子信箱',
            phone: '電話號碼',
            preference: '偏好設定',
            language: '語言',
            tempUnit: '溫度單位',
            celsius: '攝氏 (°C)',
            fahrenheit: '華氏 (°F)',
            timeZone: '時區',
            save: '儲存',
        },
        Download: {
            title: '下載中心',
            subtitle: '取得您的 Gocochain 裝置的溫度報告以及校驗證明。',
            deviceId: 'Device ID',
            hint: '輸入位於 CoTag 背後的 Device ID',
            confirm: '確認',
            footer: '有其他問題？歡迎聯繫 support@gocochain.com',
            report: '下載溫度報告',
            certificate: '下載校驗證明',
            prepare: 'Preparing to download',
        },
    },
    japanese: {
        Login: {
            email: 'メールアドレス',
            password: 'パスワード',
            rememberMe: 'ログイン状態を維持する',
            login: 'ログイン',
            forgotPassword: 'パスワードを忘れた方はこちら',
            logout: 'Logout',
            noAccount: 'まだ会員登録されてない方',
            signupForFree: '無料会員登録',
            signup: '会員登録',
            displayName: 'お名前',
            phoneNumber: '電話番号',
            passwordCheck: '英字と数字をそれぞれ1つ以上使用し、8文字以上で設定してください。',
            agree: 'に同意していただく必要があります',
            and: 'と',
            termOfUse: '利用規約',
            privacyPolicy: 'プライバシーポリシー',
            createAccount: '新規会員登録',
            alreadyHave: '会員の方はこちらから',
            checkInbox: 'メールをご確認ください',
            checkNote: '認証メールはdavid@gocochain.comに送信しました。ログインする前に、メールを確認しアカウントを認証してください。',
            confirmAccount: 'Gocochainのアカウントを登録する',
            confirmNote: "Gocochainへようこそ! Gocochainのアカウントを登録してありがとうございます。下記のボタンをクリックして会員登録を完了してください。",
            confirm: '確認する',
            successAccount: '会員登録が完了しました',
            successNote: 'この度Gocochainに登録いただき有難うございます。',
            return: 'ログインに戻る',
            reset: 'パスワードを変更する',
            confirmPassword: 'パスワードを確認する',
            mailSuccess: 'メールを送信しました',
            resetNote1: '会員登録時のメールアドレスを入力してください。',
            resetNote2: "パスワードを再設定するメールをお送りいたします。",
            send: '送信する',
            success: '成功しました',
            resetSuccessNote: "パスワードを再設定するメールをお送りしておりますのでご確認ください。",
            resetMail: '新しいパスワードの設定をお願いします',
            resetMailNote: 'このメールはパスワードの再設定手続きを行われたお客様に送信しています。以下のボタンをクリックして、新しいパスワードを設定してください。 ',
            resetMailNotice: '心当たりがない場合は、 このメールを無視してください。',
            newPassword: '新しいパスワード',
            reenterPassword: '新しいパスワード（確認用）',
            resetSuccess: 'パスワードの再設定が完了しました',
            question: 'お問い合わせは',
        },
        Overview: {
            title: 'マイページ',
            logout: 'ログアウト',
            dayUpload: '本日の登録件数',
            dayError: '本日の不合格件数',
            weekUpload: '今週の登録件数',
            weekError: '今週の不合格件数',
            dayErrorRate: '本日の不合格率',
            perDayUpload: '毎日の登録件数',
            averageTemp: '平均温度',
            maxTemp: '最高温度',
            minTemp: '最低温度',
            recentUpload: '最近の登録',
            uploadTime: '登録日時',
            district: '地区',
            deliveryCompany: '発送者',
            pass: '合格',
            fail: '不合格',
            range: '時間範囲',
            currentWeek: '今週',
            lastWeek: '先週',
            currentMonth: '今月',
            lastMonth: '先月',
            errorRate: '不合格率',
            failLastedTime: '不合格の継続時間',
            passAverageTemp: '合格の平均温度',
            failAverageTemp: '不合格の平均温度',
            pass: '合格',
            fail: '不合格',
            day: '日',
            hour: '時',
            minute: '分',
            uploadCount: '登録件数',
            errorCount: '不合格件数',
            time: '時間',
            region: '地区',
            company: '会社',
            status: '状態',
        },
        Pending: {
            title: '未登録',
            add: '追加',
            startTime: '開始時間',
            endTime: '終了時間',
            search: '検索',
            config: '設定',
            edit: '編集',
            export: '書き出し',
            confirm: '確認',
            Id: 'No.',
            deviceId: '機器 ID',
            recordTime: '記録時間',
            count: 'データ件数',
            timeZone: '時間帯',
            district: '地区',
            deliveryCompany: '発送者',
            trackNumber: '追跡番号',
            status: '状態',
            pass: '合格',
            fail: '不合格',
            tempRecordList: '温度記録リスト',
            time: '時間',
            temp: '温度',
            trendGraph: 'トレンドグラフ',
            duration: '持続時間',
            to: 'から',
            outof: '越え',
            for: 'まで',
            range: '範囲',
            minutes: '分',
            continuous: '単一イベント',
            cumulative: '累計',
            failAlarm: '不合格警報',
            D: '日',
            H: '時',
            M: '分',
            note: '備考',
            Add: {
                addCotag: 'CoTagを追加する',
                deviceId: '機器 ID',
                add: '追加',
                confirmText: 'この追加IDを確認する',
                confirm: '確認',
                cancel: 'キャンセル',
                addSuccess: '成功しました',
                addFail: '失敗しました',
            },
            Edit: {
                edit: '編集',
                deviceId: '機器　ID',
                information: '荷物情報',
                configure: '設定',
                shipmentNumber: '輸送番号',
                shipper: '発送者',
                carrier: '運送業者',
                consignee: '受取人',
                startLocation: '発送地',
                endLocation: '発送先',
                note: '備考',
                failAlarm: '不合格警報',
                excursionType: '警報種別',
                singleEvent: '単一イベント',
                cumulative: '累計',
                S: '秒',
                M: '分',
                H: '時',
                D: '日',
                upperLimit: '温度上限',
                lowerLimit: '温度下限',
                duration: '持続時間',
                dataLogging: 'データ記録',
                loggingInterval: '記録間隔',
                alarmNotification: '警報通知',
                email: 'メールアドレス',
                saveAll: '保存する',
                saveSuccess: '保存完了',
            },
        },
        Records: {
            title: '登録成功',
            startTime: '開始時間',
            endTime: '終了時間',
            search: '検索',
            config: '設定',
            edit: '編集',
            export: '書き出し',
            confirm: '確認',
            Id: 'No.',
            deviceId: '機器 ID',
            recordTime: '記録時間',
            count: 'データ件数',
            timeZone: '時間帯',
            district: '地区',
            deliveryCompany: '発送者',
            trackNumber: '追跡番号',
            status: '状態',
            pass: '合格',
            fail: '不合格',
            normal: '正常',
            abnormal: '異常',
            tempRecordList: '温度記録リスト',
            time: '時間',
            temp: '温度',
            trendGraph: 'トレンドグラフ',
            duration: '持続時間',
            to: 'から',
            outof: '越え',
            for: 'まで',
            range: '範囲',
            minutes: '分',
            continuous: '単一イベント',
            cumulative: '累計',
            failAlarm: '不合格警報',
            D: '日',
            H: '時',
            M: '分',
            note: '備考',
            Edit: {
                edit: '編集',
                deviceId: '機器 ID',
                information: '荷物情報',
                configure: '設定',
                shipmentNumber: '輸送番号',
                shipper: '発送者',
                carrier: '運送業者',
                consignee: '受取人',
                startLocation: '発送地',
                endLocation: '発送先',
                note: '備考',
                failAlarm: '不合格警報',
                excursionType: '警報種別',
                singleEvent: '単一イベント',
                cumulative: '累計',
                S: '秒',
                M: '分',
                H: '時',
                D: '日',
                upperLimit: '温度上限',
                lowerLimit: '温度下限',
                duration: '持続時間',
                dataLogging: 'データ記録',
                loggingInterval: '記録間隔',
                alarmNotification: '警報通知',
                email: 'メールアドレス',
                saveAll: '保存する',
            },
        },
        RealTime: {
            title: 'リアルタイム監視',
            add: '追加',
            edit: '編集',
            normal: '正常',
            abnormal: '異常',
            search: '検索',
            confirm: '確認',
            Edit: {
                edit: '編集',
                deviceId: '機器 ID',
                information: '荷物情報',
                configure: '設定',
                shipmentNumber: '輸送番号',
                shipper: '発送者',
                carrier: '運送業者',
                consignee: '受取人',
                startLocation: '発送地',
                endLocation: '発送先',
                note: '備考',
                failAlarm: '不合格警報',
                excursionType: '警報種別',
                singleEvent: '単一イベント',
                cumulative: '累計',
                S: '秒',
                M: '分',
                H: '時',
                D: '日',
                upperLimit: '温度上限',
                lowerLimit: '温度下限',
                duration: '持続時間',
                dataLogging: 'データ記録',
                loggingInterval: '記録間隔',
                alarmNotification: '警報通知',
                email: 'メールアドレス',
                saveAll: '保存する',
                saveSuccess: '保存完了',
            },
        },
        Information: {
            title: '設定',
            account: 'アカウント',
            displayName: '表示名',
            email: 'メールアドレス',
            phone: '電話番号',
            preference: '環境設定',
            language: '言語',
            tempUnit: '温度単位',
            celsius: '摂氏 (°C)',
            fahrenheit: '華氏 (°F)',
            timeZone: '時間帯',
            save: '保存',
        },
        Download: {
            title: 'ダウンロードセンター',
            subtitle: 'Gocochain機器の温度レポートおよび校正証明書をダウンロードする。',
            deviceId: '機器 ID',
            hint: 'CoTagの裏面にある機器IDを入力してください。',
            confirm: '確認',
            footer: 'お問い合わせはsupport@gocochain.comへ',
            report: '温度レポートをダウンロードする',
            certificate: '校正証明書をダウンロードする',
            prepare: '準備中',
        },
    },
};
